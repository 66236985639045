
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { Link } from 'react-router-dom';
import {baseUrl, limit, formatDate} from "../../comman/config";
import Loader from '../../components/Loader'
import * as XLSX from 'xlsx';
import { DateSessionReportFilter } from "../../components/Filter/DateSessionReportFilter";

function List() {

    const today = new Date().toISOString().split("T")[0];    
    const [filteredList, setFilteredList] = useState([]);
    const [reportList, setReportList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [popupOpen, setPopupOpen] = useState(false);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [session, setSession] = useState("");
    const [item, setItem] = useState("");
    const [method, setMethod] = useState("");
    const [summary, setSummary] = useState(true);
    const [reportType, setReportType] = useState(1);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        
            getShortageList(); 
        
    }, [fromDate,toDate, session, item, method]);


    useEffect(() => {
        let filterelist;
        if(method && method.length > 0){
            const methodIds = method.map(method => method.value);
            filterelist = reportList.filter((item) => methodIds.includes(item.measurements));
        } else{
            filterelist = reportList;
        }  
        setFilteredList(filterelist);
    }, [ method, reportList]);
    const getShortageList = async () => {
        
        setPopupOpen(true);
        setLoading(true)
        const response = await axios.get(`${baseUrl}/reports/master_report?page=${page}&limit=${limit}&fromDate=${fromDate}&toDate=${toDate}&session=${session?.value}&item=${JSON.stringify(item)}&method=${JSON.stringify(method)}`, {
           
        });
       
        setLoading(false)
        setPopupOpen(false);
        setReportList(response.data.data);
        setFilteredList(response.data.data);
        setTotalItem(response.data.totalItem);
    }

    const handelFilterChange= (fromDate, toDate, session, item, method) => {
        console.log(item);
        setFromDate(fromDate);
        setToDate(toDate);
        setSession(session);
        setItem(item);
        setMethod(method);
        
        
    };

    // const hadelSummary = (summary) => {
    //     setSummary(summary);
    // }

    const handelReportChange = (data) => {
        console.log(data);
        setReportType(data.value);
    }
   
    const handleExport = () => {
        const header = ["Date", "Session", "Menu Item", "Planned count"];
        if(reportType != 2 && header.push("Raw Mat. per 100 person"));
        header.push( "Planned Raw Qty");
        if(reportType != 3 && header.push("Batch"));
        header.push("Actual Raw Qty.");
        if(reportType != 3 && header.push("Measurement Method"));
        if(reportType != 3 && reportType != 2 && header.push("Std.RM to FG Ratio"));
        if(reportType != 3 && reportType != 2 && header.push("Std. FG Qty"));
        if(reportType != 3 && reportType != 2 && header.push("Ves Name"));
        if(reportType != 3 && header.push("Current RM to FG Ratio"));
        header.push("Finished Qty");
        header.push("Std FG Consumption per person");
        header.push("Food Qty.(Ltrs./kgs. based)");
        if(reportType != 3 && reportType != 2 && header.push("Std. RM to Bowl Ratio"));
        if(reportType != 3 && reportType != 2 && header.push("Std. Inital Bowls"));
        if(reportType != 3 && header.push("Actual 1 Bowl qty"));
        if(reportType != 3 && header.push("No of Actual Bowls"));
        if(reportType != 3 && header.push("Current RM to Bowl Ratio"));
        if(reportType != 3 && header.push("Persons Per bowl"));
        if(reportType != 3 && header.push("Food Qty.(Bowl based)"));
        if(reportType != 2 && header.push("Pass Issued"));
        if(reportType != 2 && header.push("Dish issued as per API"));
        if(reportType != 2 && header.push("Final Dish count"));
        if(reportType != 2 && header.push("Excess FG Qty"));
        if(reportType != 2 && header.push("Excess RM Qty"));
        if(reportType != 2 && header.push("Excess Bowl Qty"));
        if(reportType != 3 && reportType != 2 && header.push("Net FG Qty"));
        if(reportType != 3 && reportType != 2 && header.push("Net RM Qty"));
        if(reportType != 3 && reportType != 2 && header.push("Net Bowl Qty"));
        if(reportType != 2 && header.push("FG consumption Per Person"));
        if(reportType != 2 && header.push("Raw Mat. Per 100 Person"));
        if(reportType != 2 && header.push("Avg persons per bowl"));
        if(reportType != 2 && header.push("Density"));

        const data =   filteredList.map((item) => {
            let resultArray = [];
            resultArray.push(formatDate(item.date));
            resultArray.push(item.session_name);
            resultArray.push(`${item.item_name} (${item.eng_name})`);
            resultArray.push(parseInt(item.raw_material?.estimated_count));
            if(reportType != 2 && resultArray.push(item.raw_material.ras_qty_per_100_person));
            resultArray.push(item.raw_material.planned_rm_qty);
            if(reportType != 3 && resultArray.push(item.batch));
            resultArray.push(item.batch_raw_material_qty);
            if(reportType != 3 && resultArray.push(item.methodName?.name ?? "-"));
            if(reportType != 3 && reportType != 2 && resultArray.push(item.consumption_master?.rm_to_fg_ratio ?? 0));
            if(reportType != 3 && reportType != 2 && resultArray.push(item.std_fg_qty));
            if(reportType != 3 && reportType != 2 && resultArray.push(item.vesselName?.name ?? "-"));
            if(reportType != 3 && resultArray.push(item.current_rm_to_fg_ratio ?? 0));
            resultArray.push(item.finishedQty ?? 0);
            resultArray.push(item.std_fg_consumption_per_person ?? 0);
            resultArray.push(item.food_qty ?? 0);
            if(reportType != 3 && reportType != 2 && resultArray.push(item.std_rm_to_bowl_ratio ?? 0));
            if(reportType != 3 && reportType != 2 && resultArray.push(item.std_inital_bowl ?? 0));
            if(reportType != 3 && resultArray.push((item.no_of_rotis_in_1_bowl_icebox ?? 0)));
            if(reportType != 3 && resultArray.push(item.no_of_actual_bowls));
            if(reportType != 3 && resultArray.push(item.current_rm_to_bowl_ratio));
            if(reportType != 3 && resultArray.push(item.person_per_bowl ?? 0));
            if(reportType != 3 && resultArray.push(item.food_qty_bowl ?? 0));
            if(reportType != 2 && resultArray.push(item.pass_issued ?? 0));
            if(reportType != 2 && resultArray.push(item.dish_issue_count ?? 0));
            if(reportType != 2 && resultArray.push(item.final_dish_count ?? 0));
            if(reportType != 2 && resultArray.push(item.excess_fg_qty ?? 0));
            if(reportType != 2 && resultArray.push(item.excess_rm_qty ?? 0));
            if(reportType != 2 && resultArray.push(item.excess_bowl_qty ?? 0));
            if(reportType != 3 && reportType != 2 && resultArray.push(item.net_fg_qty ?? 0));
            if(reportType != 3 && reportType != 2 && resultArray.push(item.net_rm_qty ?? 0));
            if(reportType != 3 && reportType != 2 && resultArray.push(item.net_bowl_qty ?? 0));
            if(reportType != 2 && resultArray.push(item.fg_consumption_per_person ?? 0));
            if(reportType != 2 && resultArray.push(item.raw_mat_per_100_person ?? 0));
            if(reportType != 2 && resultArray.push(item.avg_person_per_bowl ?? 0));
            if(reportType != 2 && resultArray.push(item.density ?? "-"));
            return resultArray;
        })
        
        // const csv = [header].concat(
        //     filteredList.map((item, index) => {
        //         let resultArray = [];
        //         resultArray.push(formatDate(item.date));
        //         resultArray.push(item.session_name);
        //         resultArray.push(`${item.item_name} (${item.eng_name})`);
        //         resultArray.push(parseInt(item.raw_material?.estimated_count));
        //         if(reportType != 2 && resultArray.push(item.raw_material.ras_qty_per_100_person));
        //         resultArray.push(item.raw_material.planned_rm_qty);
        //         if(reportType != 3 && resultArray.push(item.batch));
        //         resultArray.push(item.batch_raw_material_qty);
        //         if(reportType != 3 && resultArray.push(item.methodName?.name ?? "-"));
        //         if(reportType != 3 && reportType != 2 && resultArray.push(item.consumption_master?.rm_to_fg_ratio ?? 0));
        //         if(reportType != 3 && reportType != 2 && resultArray.push(item.std_fg_qty));
        //         if(reportType != 3 && reportType != 2 && resultArray.push(item.vesselName?.name ?? "-"));
        //         if(reportType != 3 && resultArray.push(item.current_rm_to_fg_ratio ?? 0));
        //         resultArray.push(item.finishedQty ?? 0);
        //         resultArray.push(item.std_fg_consumption_per_person ?? 0);
        //         resultArray.push(item.food_qty ?? 0);
        //         if(reportType != 3 && reportType != 2 && resultArray.push(item.std_rm_to_bowl_ratio ?? 0));
        //         if(reportType != 3 && reportType != 2 && resultArray.push(item.std_inital_bowl ?? 0));
        //         if(reportType != 3 && resultArray.push((item.no_of_rotis_in_1_bowl_icebox ?? 0)));
        //         if(reportType != 3 && resultArray.push(item.no_of_actual_bowls));
        //         if(reportType != 3 && resultArray.push(item.current_rm_to_bowl_ratio));
        //         if(reportType != 3 && resultArray.push(item.person_per_bowl ?? 0));
        //         if(reportType != 3 && resultArray.push(item.food_qty_bowl ?? 0));
        //         if(reportType != 2 && resultArray.push(item.pass_issued ?? 0));
        //         if(reportType != 2 && resultArray.push(item.dish_issue_count ?? 0));
        //         if(reportType != 2 && resultArray.push(item.final_dish_count ?? 0));
        //         if(reportType != 2 && resultArray.push(item.excess_fg_qty ?? 0));
        //         if(reportType != 2 && resultArray.push(item.excess_rm_qty ?? 0));
        //         if(reportType != 2 && resultArray.push(item.excess_bowl_qty ?? 0));
        //         if(reportType != 3 && reportType != 2 && resultArray.push(item.net_fg_qty ?? 0));
        //         if(reportType != 3 && reportType != 2 && resultArray.push(item.net_rm_qty ?? 0));
        //         if(reportType != 3 && reportType != 2 && resultArray.push(item.net_bowl_qty ?? 0));
        //         if(reportType != 2 && resultArray.push(item.fg_consumption_per_person ?? 0));
        //         if(reportType != 2 && resultArray.push(item.raw_mat_per_100_person ?? 0));
        //         if(reportType != 2 && resultArray.push(item.avg_person_per_bowl ?? 0));
        //         if(reportType != 2 && resultArray.push(item.density ?? "-"));
        //         return resultArray.join(',');
        //     })
        // ).join('\n');
        // const blob = new Blob([csv], { type: 'text/csv' });
        // const url = URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.setAttribute('href', url);
        // a.setAttribute('download', "master_report");
        // a.click();
        const worksheet = XLSX.utils.aoa_to_sheet([header, ...data]);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Master Report");

        XLSX.writeFile(workbook, `Master_Report_${fromDate ? '_' + formatDate(fromDate) : ''}to${toDate ? '_' + formatDate(toDate) : ''}${session?.label ? '_' + session.label : ''}.xlsx`);
    }
    
   
      


    return (
        <div className="p-4">
            {loading && <Loader/>}
            <div className="flex justify-between items-center py-4 px-6">
                <h1 className="text-2xl font-bold">Master Report</h1>
                <div>
                    <Link to="/">
                        <button className="px-4 py-2 bg-blue-500 text-white rounded-md mr-4 hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-200">Back</button>
                    </Link>
                </div>
            </div>
            <div className="relative shadow-md sm:rounded-lg">
            <DateSessionReportFilter 
            handleFilterChange={handelFilterChange} 
            // hadelSummary={hadelSummary} 
            handleExport={handleExport}
            handelReportChange={handelReportChange} 
    
            />


                <div className="overflow-x-auto">
                <table className="w-full  text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        
                        <th scope="col" className="px-2 py-1 border border-black text-center "></th>
                        <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                        <th scope="col" className="px-2 py-1 border border-black text-center"></th>
                        <th scope="col" colSpan={reportType != 2 ? 3 : 2} className="px-4 py-3 border border-black text-center">Planned Production</th>
                        <th scope="col" colSpan={reportType != 3 ? 3 : 2} className="px-2 py-1 border border-black text-center ">Raw Material</th>
                        <th scope="col" colSpan={reportType != 2 ? (reportType != 3 ? 7 : 3) : 5} className="px-2 py-1 border border-black text-center ">Finished Goods (lts./Kgs.)</th>
                        {reportType != 3 && <th scope="col" colSpan={reportType != 2 ? 7 : 5} className="px-2 py-1 border border-black text-center ">Finished Goods (Bowl)</th>}
                        {reportType != 2 && <th scope="col" colSpan={3} className="px-2 py-1 border border-black text-center ">Dish issued</th>}
                        {reportType != 2 && <th scope="col" colSpan={3} className="px-2 py-1 border border-black text-center ">Excess / Shortage Qty.</th>}
                        {reportType != 2 && reportType != 3  && <th scope="col" colSpan={3} className="px-2 py-1 border border-black text-center ">NET Quantity  details</th>}
                        {reportType != 2 && <th scope="col" colSpan={3} className="px-2 py-1 border border-black text-center ">Net Consumption</th>}
                        {reportType != 2 && <th scope="col"  className="px-2 py-1 border border-black text-center "></th>}
                        {reportType != 2 && <th scope="col"  className="px-2 py-1 border border-black text-center "></th>}
                    </tr>
                        <tr>
                        
                            <th scope="col" className="px-2 py-1 border border-black text-center ">Date</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Session</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Menu Item</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Planned count</th>
                            {reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center bg-green-300">Raw Mat. per 100 person</th>}
                            <th scope="col" className="px-2 py-1 border border-black text-center">Planned Raw Qty</th>
                            {reportType != 3 && <th scope="col" className="px-2 py-1 border border-black text-center">Batch</th>}
                            <th scope="col" className="px-2 py-1 border border-black text-center">Actual Raw Qty.</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Production Ras</th>
                            {reportType != 3 && <th scope="col" className="px-2 py-1 border border-black text-center">Measure Method</th>}
                            {reportType != 2 && reportType != 3 && <th scope="col" className="px-2 py-1 border border-black text-center">Std.RM to FG Ratio</th>}
                            {reportType != 2 && reportType != 3 && <th scope="col" className="px-2 py-1 border border-black text-center">Std. FG Qty</th>}
                            {/* {reportType != 2 && reportType != 3 && <th scope="col" className="px-2 py-1 border border-black text-center">Ves Name</th>} */}
                            {reportType != 3 && <th scope="col" className="px-2 py-1 border border-black text-center">Current RM to FG Ratio</th>}
                            <th scope="col" className="px-2 py-1 border border-black text-center">Finished Qty</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center">Std FG Consump. per person</th>
                            <th scope="col" className="px-2 py-1 border border-black text-center bg-teal-300">Food Qty.(Ltrs./kgs. based)</th>
                            {reportType != 3 && reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Std. RM to Bowl Ratio</th>}
                            {reportType != 3 && reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Std. Inital Bowls</th>}
                            {reportType != 3 && <th scope="col" className="px-2 py-1 border border-black text-center">Actual 1 Bowl qty</th>}
                            {reportType != 3 && <th scope="col" className="px-2 py-1 border border-black text-center">No of Actual Bowls</th>}
                            {reportType != 3 && <th scope="col" className="px-2 py-1 border border-black text-center">Current RM to Bowl Ratio</th>}
                            {reportType != 3 && <th scope="col" className="px-2 py-1 border border-black text-center">Persons Per bowl</th>}
                            {reportType != 3 && <th scope="col" className="px-2 py-1 border border-black text-center bg-teal-300">Food Qty.(Bowl based)</th>}
                            {reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Pass Issued</th>}
                            {reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Dish issued as per API</th>}
                            {reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Final Dish count</th>}
                            {reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Excess FG Qty</th>}
                            {reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Excess RM Qty</th>}
                            {reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Excess Bowl Qty</th>}
                            {reportType != 3 && reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Net FG Qty</th>}
                            {reportType != 3 && reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Net RM Qty</th>}
                            {reportType != 3 && reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Net Bowl Qty</th>}
                            {reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">FG consump. Per Person</th>}
                            {reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center bg-green-300">Raw Mat. Per 100 Person</th>}
                            {reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Avg persons per bowl</th>}
                            {reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Std Density</th>}
                            {reportType != 2 && <th scope="col" className="px-2 py-1 border border-black text-center">Live Density</th>}
                            </tr>
                    </thead>
                    <tbody>
                    {filteredList.map((item, index) => (
                        // <tr key={index} className={`${item.summary ? 'bg-lime-200' : (item.methodName?.id == 1 ? 'bg-blue-200' : (item.methodName?.id == 2 ? 'bg-orange-200' : 'bg-white dark:bg-gray-800 dark:border border-black-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 '))} border border-black-b`}>
                        <tr key={index} className={`${(item.methodName?.id == 1 ? 'bg-blue-200' : (item.methodName?.id == 2 ? 'bg-orange-200' : 'bg-lime-200 '))} border border-black-b`}>
                            <td className="px-2 py-1 border border-black text-center">{formatDate(item.date)}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.session_name}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.eng_name}</td>
                            <td className="px-2 py-1 border border-black text-center">{parseInt(item.raw_material?.estimated_count)}</td>
                            {reportType != 2 &&<td className="px-2 py-1 border border-black text-center">{item.raw_material.ras_qty_per_100_person}</td>}
                            <td className="px-2 py-1 border border-black text-center">{item.raw_material.planned_rm_qty}</td>
                            {reportType != 3 && <td className="px-2 py-1 border border-black text-center">{item.batch}</td>}
                            <td className="px-2 py-1 border border-black text-center">{item.batch_raw_material_qty}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.production_ras ?? 0}</td>
                            {reportType != 3 &&<td className="px-2 py-1 border border-black text-center">{item.methodName?.name ?? '-'}</td>}
                            {reportType != 2 && reportType != 3 && <td className="px-2 py-1 border border-black text-center">{item.rm_to_fg_ratio ?? 0}</td>}
                            {reportType != 2 && reportType != 3 && <td className="px-2 py-1 border border-black text-center">{item.std_fg_qty}</td>}
                            {/* {reportType != 2 && reportType != 3 && <td className="px-2 py-1 border border-black text-center">{item.vesselName?.name ?? "-"}</td>} */}
                            {reportType != 3 && <td className="px-2 py-1 border border-black text-center">{item.current_rm_to_fg_ratio ?? 0}</td>}
                            <td className="px-2 py-1 border border-black text-center">{item.finishedQty ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.std_fg_consumption_per_person ?? 0}</td>
                            <td className="px-2 py-1 border border-black text-center">{item.food_qty ?? 0}</td>
                            {reportType != 3 && reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.std_rm_to_bowl_ratio ?? 0}</td>}
                            {reportType != 3 && reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.std_inital_bowl ?? 0}</td>}
                            {reportType != 3 && <td className="px-2 py-1 border border-black text-center">{(item.no_of_rotis_in_1_bowl_icebox ?? 0)}</td>}
                            {reportType != 3 && <td className="px-2 py-1 border border-black text-center">{item.no_of_actual_bowls}</td>}
                            {reportType != 3 && <td className="px-2 py-1 border border-black text-center">{item.current_rm_to_bowl_ratio}</td>}
                            {reportType != 3 && <td className="px-2 py-1 border border-black text-center">{item.person_per_bowl ?? 0}</td>}
                            {reportType != 3 && <td className="px-2 py-1 border border-black text-center">{item.food_qty_bowl ?? 0}</td>}
                            {reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.pass_issued ?? 0}</td>}
                            {reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.dish_issue_count ?? 0}</td>}
                            {reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.final_dish_count ?? 0}</td>}
                            {reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.excess_fg_qty ?? 0}</td>}
                            {reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.excess_rm_qty ?? 0}</td>}
                            {reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.excess_bowl_qty ?? 0}</td>}
                            {reportType != 3 && reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.net_fg_qty ?? 0}</td>}
                            {reportType != 3 && reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.net_rm_qty ?? 0}</td>}
                            {reportType != 3 && reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.net_bowl_qty ?? 0}</td>}
                            {reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.fg_consumption_per_person ?? 0}</td>}
                            {reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.raw_mat_per_100_person ?? 0}</td>}
                            {reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.avg_person_per_bowl ?? 0}</td>}
                            {reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.std_density ?? 0}</td>}
                            {reportType != 2 && <td className="px-2 py-1 border border-black text-center">{item.density ?? "-"}</td>}

                            
                        </tr>))}
           
                    </tbody>
                </table>
                </div>
            </div>
        </div>
  );
}

export default List;